import React from "react"
import { Helmet } from "react-helmet";
import SEO from "../components/seo/seo"
import EmailBox from "../components/email-box/email-box"

const ConsultationPage = () => (
  <>
    <SEO title="Book consultation | heisentech" />
    <section className="section consultation">
      <div className="container big">
        <h4 className="section-title">Book an one-on-one <br/>consultation with our executives</h4>
        <div style={{ width: '100%' }} className="meetings-iframe-container" data-src="https://meetings.hubspot.com/business-consultant?embed=true"></div>
        <div className="or-label">or</div>
        <h4 className="section-title">Just drop your email address</h4>
        <EmailBox/>
      </div>
    </section>
    <Helmet>
      <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
    </Helmet>
  </>
);

export default ConsultationPage